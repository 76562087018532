import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Divider,
    IconButton,
    IconButtonProps,
    styled,
    Typography
} from '@mui/material';
import React, {useState} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {red} from "@mui/material/colors";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CommonAction, ProductAdminDto, ProductUseCaseDto} from "../types/PurchaseTypes";
import Grid2 from "@mui/material/Unstable_Grid2";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme}) => ({
    margin: "auto",
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({expand}: { expand: boolean }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({expand}: { expand: boolean }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const PurchaseCard = ({data, useCases}: { data: ProductAdminDto, useCases: Map<string, ProductUseCaseDto> }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{width: "100%"}}>
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                        A
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={data.name}
                subheader={"nevermind"}
            />
            <CardMedia
                component="img"
                height="100"
                image="/static/images/cards/paella.jpg"
                alt="Paella dish"
            />
            <CardContent>
                <Typography>sku: {data.sku}</Typography>
                <Typography>price: {data.price?.price}</Typography>
                <Typography>currency: {data.price?.currency}</Typography>
            </CardContent>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon color="primary"/>
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {Object.keys(data.actions.data).map(it => <CardContent sx={{textAlign: "center"}}>
                    {it}
                    <Grid2 container xs={12} sx={{textAlign: "left"}}>
                        {
                            ((data.actions.data as any)[it] as CommonAction[]).map((action: CommonAction) => <>
                                <Divider sx={{m: 1, width: "100%"}} color={"white"}/>
                                <Typography
                                    variant={"h6"}>{((useCases as any)[action.type] as ProductUseCaseDto).name}</Typography>
                                <Grid2 xs={12} container>
                                    {Object.keys(action.params).map((param: string) => <Grid2 m={1}>
                                        {((useCases as any)[action.type] as ProductUseCaseDto).params.filter((item) => item.paramId == param)[0].name}
                                        : <pre>{JSON.stringify((action.params as any)[param], null, 4)}</pre>
                                    </Grid2>)}
                                </Grid2>
                            </>)
                        }
                    </Grid2>
                </CardContent>)}
            </Collapse>
        </Card>
    );
};

export default PurchaseCard;