import React, {useEffect, useState} from 'react';
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {Box, Divider, List, ListItemButton, ListItemText} from "@mui/material";
import {ProductAdminDto, ProductUseCaseDto} from "../../../widgets/admin/types/PurchaseTypes";
import Grid2 from "@mui/material/Unstable_Grid2";
import PurchaseCard from "../../../widgets/admin/addable_components/PurchaseCard";

const IapPage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const [selectedComponents, setSelectedComponents] = useState<undefined | ProductAdminDto[]>(undefined)
    const [purchases, setPurchases] = useState<Map<string, Map<string, ProductAdminDto[]>> | undefined>(undefined)
    const [selectedStore, setSelectedStore] = useState<string | undefined>(undefined)
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined)
    const [allStores, setAllStores] = useState<string[] | undefined>(undefined)
    const [allTypes, setAllTypes] = useState<string[] | undefined>(undefined)
    const [usecases, setUsecases] = useState<Map<string, ProductUseCaseDto> | undefined>(undefined)
    useEffect(() => {
        request.get("admin/purchases").then((res) => res.json()).then(res => setPurchases(res))
    }, []);

    useEffect(() => {
        request.get("admin/purchases/usecases").then((res) => res.json()).then(res => {
            setUsecases(res)
        })
    }, []);

    useEffect(() => {
        if (purchases) {
            setAllStores(Object.keys(purchases))
            var types = Object.values(purchases)
            if (types.length > 0) {
                setAllTypes(Object.keys(types[0]))
            }
        }
    }, [purchases]);

    useEffect(() => {
        if (selectedType) {
            setSelectedComponents((purchases as any)[selectedStore!!][selectedType!!])
        }
    }, [selectedType]);

    return (
        <>
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center"
                    }}
                >
                    {
                        allStores?.map((it, key) => <ListItemButton
                            key={key}
                            onClick={() => setSelectedStore(it)}
                        >
                            <ListItemText>{it}</ListItemText>
                        </ListItemButton>)
                    }
                </List>
            </Box>
            {
                selectedStore ?
                    <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                        <List
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center"
                            }}
                        >
                            {
                                allTypes?.map((it, key) => <ListItemButton
                                    key={key}
                                    onClick={() => setSelectedType(it)}
                                >
                                    <ListItemText>{it}</ListItemText>
                                </ListItemButton>)
                            }
                        </List>
                    </Box> : <></>
            }
            {
                selectedComponents ? <Box>
                    <Divider/>
                    <Grid2 xs={12} container display="flex" justifyContent="center">
                        {usecases && selectedComponents.map((it) => <Grid2 display="flex" justifyContent="center"
                                                                           xs={12} p={1}>
                            <PurchaseCard data={it} useCases={usecases}/>
                        </Grid2>)}
                    </Grid2>
                </Box> : <></>
            }
        </>
    );
};

export default IapPage;